<template>
  <!-- <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" /> -->
  <div class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal
      :titlemenu1="titlemenu1"
      :to1="goTo()"
      titlemenu2="Health Log"
      :to2="'/healthlogoverview'"
      titlemenu3="Add Medication"
    />

    <div class="w-full pl-0 pt-8 pb-8 text-left font-proximaMedium">
      <div class="mt-4 text-left font-proximaLight text-sm text-subheaderText">
        <div>
          <h5>Medication</h5>
          <input
            type="text"
            placeholder="Enter the scientific name of the medication"
            v-model="medication"
            class="w-full mt-1 py-4 px-6 rounded-md"
          />
        </div>
        <div class="mt-4">
          <h5>Frequency</h5>
          <div class="mt-1 p-4 bg-white rounded-md">
            <div class="flex flex-col text-left">
              <span class="mb-1 text-subheaderText">First Intake</span>
              <date-picker
                type="datetime"
                format="dddd-MM-YYYY HH:mm A"
                v-model="intakeDatepicker"
                placeholder="Select date"
                class="w-full my-1 text-sm"
              ></date-picker>
            </div>
            <div
              class="mt-4 text-sm flex flex-wrap justify-between items-center"
            >
              <InputRadioEach
                v-for="(data, i) in intakeTime"
                :key="i"
                :title="data.name"
                name="intakeTime"
                @inputVal="inputIntakeDose"
              />
            </div>
            <div
              class="mt-4 pb-1 text-sm border-b-2 flex flex-wrap justify-between items-center"
            >
              <InputRadioEach
                title="1x"
                name="intakeDaily"
                @inputVal="inputIntakeDaily"
              />
              <InputRadioEach
                title="2x"
                name="intakeDaily"
                @inputVal="inputIntakeDaily"
              />
              <div class="flex mr-2">
                <div class="flex justify-start">
                  <InputRadioText
                    name="intakeDaily"
                    width="w-3/12"
                    placeholder="..."
                    @inputValText="inputIntakeDaily"
                  />
                </div>
                <span class="pl-2">x</span>
              </div>
            </div>
            <div class="mt-4 flex">
              <span class="mr-4">Duration</span>
              <div class="flex justify-between items-start">
                <div class="flex mr-2">
                  <div class="flex justify-start">
                    <InputRadioText
                      name="duration"
                      width="w-3/12"
                      placeholder="..."
                      @inputValText="inputIntakeDuration"
                    />
                  </div>
                  <span class="pl-2">days</span>
                </div>
                <InputRadioEach
                  title="forever"
                  name="duration"
                  @inputVal="inputIntakeDuration"
                />
              </div>
            </div>
            <SubmitButton
              background="bg-primary"
              title="Turn off reminder(s)"
              marginT="mt-4"
            />
          </div>
        </div>
        <div class="mt-4">
          <h5>Brand / Dosage</h5>
          <div class="mt-1 bg-white rounded-md px-6 pb-4 pt-6">
            <input
              type="text"
              placeholder="Brand name and amount per intake (optional)"
              class="w-full border-b-2"
              v-model="brandDosage"
            />
          </div>
        </div>
      </div>
    </div>
    <SubmitButton
      background="bg-primary"
      title="Add"
      marginT="mt-0"
      width="w-10/12"
      @click.native="submitAdd"
    />
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import AddIcon from "../../icons/AddIcon.vue";
import FormError from "../../warning/FormError.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import Loader from "../../loader/Loader.vue";
import BorderedButtonVue from "../../buttons/BorderedButton.vue";
import SelectOptions from "../../forms/SelectOptions.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";
import InputRadio from "../../forms/InputRadio.vue";
import InputRadioText from "../../forms/InputRadioText.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters, mapActions } from "vuex";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    AddIcon,
    FormError,
    HeaderGlobal,
    Loader,
    SubmitButton,
    BorderedButtonVue,
    SelectOptions,
    DatePicker,
    InputRadioEach,
    InputRadioText,
    InputRadio,
  },
  data() {
    return {
      pet: {},
      titlemenu1: "",
      treatmentType: [
        { id: "1", name: "Broad spectrum dewormer (intestinal parasites)" },
        { id: "2", name: "Heartworm treatment" },
        { id: "3", name: "External parasite treatment (oral or topical)" },
        { id: "4", name: "Flea and tick preventative collar" },
      ],
      intakeTime: [
        { id: "1", name: "daily" },
        { id: "2", name: "weekly" },
        { id: "3", name: "monthly" },
      ],
      intakeDatepicker: null,
      medication: "",
      intakeDose: "",
      intakeDaily: "",
      intakeDuration: "",
      brandDosage: null,
    };
  },
  computed: {},
  created() {
    const petObject = localStorage.getItem("pet");
    const pet = JSON.parse(petObject);
    this.pet = pet;

    this.titlemenu1 = nameOfPet(pet.species) + " record: " + pet.callName;
    this.getMedicationTrackingOfPet(pet.petID);
  },

  methods: {
    ...mapActions({
      addMedicationTracking: "healthlog/addMedicationTracking",
      getMedicationTrackingOfPet: "healthlog/getMedicationTrackingOfPet",
    }),
    inputIntakeDose(i) {
      // console.log(i);
      this.intakeDose = i;
    },
    inputIntakeDaily(i) {
      this.intakeDaily = i;
    },
    inputIntakeDuration(i) {
      this.intakeDuration = i;
      // console.log({i});
    },
    goTo() {
      return "/petrecord";
    },
    submitAdd() {
      const req = {
        medication: this.medication,
        first_intake: this.intakeDatepicker,
        frequency: "daily", //daily weekly monthly
        frequency_times: 1,
        notification: false,
        userEmailAddress: localStorage.getItem("email"),
        petID: this.pet.petID,
        brand_dosage: this.brandDosage,
      };

      console.log(req);
      this.addMedicationTracking(req);
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
}
.mx-input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid rgba(229, 231, 235, var(--tw-border-opacity));
  border-radius: 0;
  box-shadow: none;
}
</style>